import styled from "styled-components";
import Footer from "../../components/footer";
import { FooterWrapper } from "../../components/footer/style";

export const TitleContainer = styled.section`
    width: 92%;
    border-bottom: 1px solid #666667;
    display:flex;
    flex-direction: column;
    align-items:center;
    margin: 2rem 0;
    padding-bottom: 2rem;
    
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        margin: 1rem 0;
        padding-bottom: 0;
    }
`

export const LogoLink = styled.a`
    text-decoration: none;
    width: 100%;

    @media screen and (min-width: 1024px) {
        width: 30%;
    }
`

export const TitleText = styled.h2`
    font-size: 3rem;
    font-weight: lighter;
    color: #FFFFFF;
    margin-bottom: 0px;
    padding-left: 0rem;
`

export const RoadmapWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
  padding-bottom: 2rem;
  font-family: "ManropeRegular";
  height: 2000px;

  @media screen and (min-width: 1024px) {
    height: inherit;
  }
`;

export const LogoWrapper = styled.img`
  width: 100%;
  preserveAspectRatio: none;
  object-fit: contain;
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 1550px;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    height: inherit;
    padding: 50px 0;
    height: inherit;
    width: 88%;
  }
`;

export const Line = styled.div`
  position: absolute;
  width: 5px;
  background-color: #0097a7;
  height: 100%;

  @media screen and (min-width: 1024px) {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Bullet = styled.div`
  position: relative;
  z-index: 1;
  background-color: #0097a7;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1.4px solid white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
  }
`;

export const BulletDate = styled.div`
    position: relative;
    right: 6rem;

    @media screen and (min-width: 1024px) {
        margin-top: 0;
        right: inherit;
        top: -50px;
    }

    &.last-bullet-date and (min-width: 1024px) {
        top: -35px;
    }

    .blank-space {
        @media (max-width: 1023px) {
          display: none;
        }
    }
`

export const BulletInfo = styled.div`
    position: absolute;
    left: 2rem;
    top: 0rem;

    @media screen and (min-width: 376px) {
        left: 2.5rem;
    }

    @media screen and (min-width: 1024px) {
        position: relative;
        margin-top: 0;
        left: 8rem;
        top: 0px;
    }
`

export const BulletText1 = styled.p`
    margin-bottom: 0px;
    font-size: 1.4rem;
    font-weight: 500;
`

export const ArrowBullet = styled.div`
  position: relative;
  z-index: 1;
  width: 0;
  height: 0;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    border-left: 17.5px solid transparent;
    border-right: 17.5px solid transparent;
    border-top: 17.5px solid #0097a7;

        @media screen and (min-width: 1024px) {
        transform: translateY(-50%);
        border-top: 17.5px solid transparent;
        border-bottom: 17.5px solid transparent;
        border-left: 17.5px solid #0097a7;
    }
  }
`;

export const Container = styled.div`
  background-color: #000000;
  color: #F8C012;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const Title = styled.h1`
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 40px;
`;

export const Roadmap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const Phase = styled.div`
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  font-size: 1.2rem;
  text-align: left;

  @media (min-width: 1024px) {
    width: 19rem;
    padding: 0;
    margin: 10px;
    font-size: 1.5rem;
  }
`;

export const PhaseTitle1 = styled.h2`
  color: #8c8dfc;
  margin-bottom: 15px;
`;

export const PhaseSubtitle1 = styled.h3`
  color: #F8C012;
  font-size: 1.2rem;
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

export const BulletList = styled.ul`
  list-style-type: disc;
  padding-left: 16px;
`;

export const BulletItem1 = styled.li`
  color: #FFFFFF;
  margin-bottom: 10px;
  font-size: 1.1rem;

  @media (min-width: 1024px) {
    font-size: 1.3rem;
    font-weight: 500;
  }
`;

export const StyledFooterWrapper = styled.div`
  @media (min-width: 1024px) {
    margin-top: 24rem;
  }
`;

export const RoadmapTexts = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 90%;
    row-gap: 16px;
    padding: 22px 25px 25px;
    margin-bottom: 3rem;
    border-bottom: 1px solid #666667;

    p {
        color: white;
        font-weight: 300;

        @media screen and (max-width: 475px){
            font-size: 14px;
        }
    }

    h2 {
        color: white;

        @media screen and (max-width: 475px){
            font-size: 32px;
        }
    }

    @media screen and (max-width: 992px){
        padding: 22px 25px 25px;
    }

    @media (min-width: 1024px) {
      width: 92%;
      padding: 55px 0px;
  }
`